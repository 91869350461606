header {
  background-color: rgb(115, 201, 45);
  background-position: center top;
  background-repeat: no-repeat;
  box-shadow: rgb(35 31 32 / 50%) 1px 1px 20px 0;
  border-width: 0;
  border-color: rgb(226, 226, 226);
  border-style: solid;
  background-size: cover;
  background-image: url('skillsgarden-greenscreen-1200x660.png');
  width: 100vw;
}


[class$="MuiFormControlLabel-root"],
.MuiFormControlLabel-root {
  margin: 0 4px !important;
}
